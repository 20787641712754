import { environment } from 'src/environments/environment';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlMatcher, UrlSegment, UrlMatchResult } from '@angular/router';

// -----------------------------------------------------------------------------
import { LayoutGuardComponent, LayoutWwwComponent } from '~project/layout';
// -----------------------------------------------------------------------------

// Definindo o matcher personalizado para a rota com url_handle
const signupAndBuyMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  // Verificar se o caminho base corresponde ao que queremos
  if (segments.length === 2 && segments[0].path === 'landing' && segments[1].path === 'signup-a-quote') {
    // O matcher não verifica query params diretamente, então retornamos apenas se o caminho corresponder
    return {
      consumed: segments,
      posParams: {},
    };
  }

  if (segments.length === 2 && segments[0].path === 'landing' && segments[1].path === 'signup-and-buy') {
    // O matcher não verifica query params diretamente, então retornamos apenas se o caminho corresponder
    return {
      consumed: segments,
      posParams: {},
    };
  }

  if (segments.length === 2 && segments[0].path === 'landing' && segments[1].path === 'signup-interest') {
    // O matcher não verifica query params diretamente, então retornamos apenas se o caminho corresponder
    return {
      consumed: segments,
      posParams: {},
    };
  }

  return null;
};

const routes: Routes = [
  {
    path: '',
    component: LayoutWwwComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/pages/module').then((m) => m.PagesModule),
      },
    ],
  },
  {
    path: 'section',
    component: LayoutWwwComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/pages-sections/module').then((m) => m.PagesSectionsModule),
      },
    ],
  },
  {
    path: 'guard',
    component: LayoutGuardComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/pages-guard/module').then((m) => m.PagesGuardModule),
      },
    ],
  },
  {
    path: 'guard/application',
    component: LayoutGuardComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/pages-guard/application/module').then((m) => m.ApplicationModule),
      },
    ],
  },
  {
    path: 'collective/application',
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/pages-collective/application/module').then((m) => m.ApplicationModule),
      },
    ],
  },
  {
    matcher: signupAndBuyMatcher,
    // Usar string estática, não função
    redirectTo: 'landing/register',
    // Uma alternativa seria usar component e fazer o redirecionamento lá
    pathMatch: 'full',
  },
  {
    path: 'landing',
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/pages-landing/module').then((m) => m.PagesPublicModule),
      },
    ],
  },
  {
    path: 'register',
    redirectTo: 'landing/register',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: LayoutWwwComponent,
    loadChildren: () => import('../project/modules/404/module').then((m) => m.Page404Module),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
